import React from 'react';

function Clubs() {
    return (
        <div>
            <h1>Extracurriculars</h1>
            <p>This page is still under construction :)</p>
        </div>
    );
}

export default Clubs;
